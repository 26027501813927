﻿.navbar {
    width: 100%;
    height: 50px;
    background-color: $color-nav-bar;
    padding-left: 3rem;
    padding-right: 3rem;

    @include reduceNavBarPadding {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    @include removeNavBarPadding {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .navbar-wrapper {
        width: 100%;
        height: 100%;
        margin: auto;
        display: flex;
        animation-delay: 0.25s;
        animation: fadeInNavbar 0.25s both ease;

        .brand-wrapper {
            display: flex;
            align-items: center;
            padding-right: 2em;

            @include renderMobileNavView {
                margin-right: 0.25rem;
            }
        }

        .navbar-routes {
            font-size: 1.25rem;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-grow: 1;

            .navbar-item {
                color: white;
                font-weight: 600;
                background-color: transparent;
                transition: color 0.2s ease-out, border-bottom-color 0.2s ease-out;
                display: flex;
                overflow-x: auto;
                overflow-y: hidden;
                height: 100%;
                line-height: 42px;
                font-size: 15px;
                text-decoration: none;
                text-transform: uppercase;
                text-align: center;
                cursor: pointer;

                @include renderMobileNavView {
                    font-size: 0.95rem;
                    padding: 0.75rem 0.2rem 0.75rem 0.2rem;
                }

                &:not(:first-child) {
                    margin-left: 1rem;

                    @include renderMobileNavView {
                        margin-left: 0;
                    }
                }

                &:hover {
                    color: $color-blue-highlight;
                    border-bottom-color: $color-blue-highlight !important;
                }

                &.is-active {
                    color: $color-blue-highlight !important;
                    border-bottom-color: $color-blue-highlight !important;
                    background-color: #da291c;
                }
            }
        }

        .user-wrapper {
            display: flex;
            align-items: center;
            color: white;

            span {
                font-weight: bold
            }

            @media all and (max-width: 1024px) {
                font-size: 0.8rem;
            }
        }
    }
}

@keyframes fadeInNavbar {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}