﻿/* BULMA COLOR OVERRIDES */
$red: #e93e60;
$cyan: #005daa;
$blue: $cyan;

/* COLOR */
$color-body-bg: #f7f7f7;
$color-nav-bar: #005daa;
$color-hero-is-dark: #1f2227;
$color-blue-highlight: #ffffff;

$color-subtitle: #646464;
$color-code: #363636;

/* REACT-TOASTIFY OVERRIDES */
$toastify-color-error: $red;
$toastify-toast-border-radius: 3px;

/* MIXINS */
@mixin renderTabletNavView {
  @media all and (max-width: 950px) and (min-width: 600px) {
    @content;
  }
}

@mixin renderMobileNavView {
  @media all and (max-width: 599px) {
    @content;
  }
}

@mixin removeNavBarPadding {
  @media all and (max-width: 1099px) {
    @content;
  }
}

@mixin reduceNavBarPadding {
  @media all and (max-width: 1472px) and (min-width: 1100px) {
    @content;
  }
}
