﻿/* REACT-TOASTIFY NPM PACKAGE CSS OVERRIDES */
.Toastify__toast {
    border-radius: $toastify-toast-border-radius;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: $toastify-color-error;
}

/* OVERRIDES & GENERAL STYLES */
html,
body {
    background-color: $color-body-bg;
}

hr {
    height: 1.5px;
    background-color: rgba(0, 0, 0, 0.1);
}

.subtitle {
    color: $color-subtitle;
}

.title:not(.is-spaced) + .subtitle.is-5 {
    margin-top: -1rem;
}

.box {
    padding: 1.75rem;
    border-radius: 0.25rem;
    border-color: rgba(0,0,0,.05);
    box-shadow: 0 2px 7px 0 rgba(0,0,0,.08), 0 5px 20px 0 rgba(0,0,0,.06);

    &.login-box {
        padding: 1.5rem;
    }

    &.container-box {
        min-height: 446px;
    }

    &.nopaddingTop {
        padding-top: 0
    }
}

.section {
    min-height: 50rem;
}

.container.is-fluid {
    padding-left: 0;
    padding-right: 0;
}

code {
    padding: 0.275em 0.45em;
    font-size: 0.9em;
    word-break: break-word;
    border-radius: 3px;
    color: $color-code;
    background-color: #ebedf0;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

    &.form-value {
        margin-left: 5px;
    }
}

.hero.is-dark {
    background-color: $color-hero-is-dark;

    .hero-body {
        padding: 1.25rem;

        img {
            user-select: none;
        }
    }

    .title {
        color: $color-blue-highlight;
    }
}

.form-columns {
    h5 {
        margin-bottom: 3rem !important;
    }

    .title:not(.is-spaced) + .subtitle {
        margin-top: -1rem;
    }

    .form-control-group {
        width: 95%;
        min-height: 60px;
    }
}

.is-horizontal-center {
    align-items: center;
    justify-content: center;
}

.button {
    font-weight: 600;
}

.remember-me-field {
    label {
        margin-left: auto;
        margin-right: auto;
    }

    span {
        font-size: 1.02em;
    }
}

.incrementer-buttons {
    align-items: initial;
    margin-bottom: .75rem !important;

    > .button {
        margin-bottom: 0;
        min-width: 6.25rem;

        svg {
            margin: auto;
            font-size: 1.4em;
        }

        &:not(:last-child) {
            margin-right: 0.75rem !important;
        }
    }
}

.dashboard-wrapper {
    min-height: 60rem;
    padding-bottom: 3rem;

    .card-content {
        padding: 1rem;

        .title {
            margin-bottom: 1.25rem;
        }

        .content li + li {
            margin-top: 0.75em;
        }

        .dashboard-info {
            padding: 1.25em !important;
        }
    }

    hr {
        width: 55%;
        margin: 0 auto 0.45rem;
    }
}

.dashboard-link {
    color: $color-code;
    font-weight: 700;
    padding: 0.15em 0.35em 0.15em;
    margin-right: 0.25em;
    transition: background-color 0.2s ease-out, border-bottom-color 0.2s ease-out;

    &.react {
        background-color: rgba(32, 156, 238, 0.16);
        border-bottom: 1px solid rgba(32, 156, 238, 0.25);

        &:hover {
            background-color: rgba(32, 156, 238, 0.3);
            border-bottom-color: rgba(32, 156, 238, 1);
        }
    }

    &.redux {
        background-color: rgba(118, 74, 188, 0.16);
        border-bottom: 1px solid rgba(118, 74, 188, 0.25);

        &:hover {
            background-color: rgba(118, 74, 188, 0.3);
            border-bottom-color: rgba(118, 74, 188, 1);
        }
    }

    &.bulma {
        background-color: rgba(0, 196, 167, 0.16);
        border-bottom: 1px solid rgba(0, 196, 167, 0.25);

        &:hover {
            background-color: rgba(0, 196, 167, 0.3);
            border-bottom-color: rgba(0, 196, 167, 1);
        }
    }

    &.aspcore {
        background-color: rgba(118, 74, 188, 0.16);
        border-bottom: 1px solid rgba(118, 74, 188, 0.25);

        &:hover {
            background-color: rgba(118, 74, 188, 0.3);
            border-bottom-color: rgba(118, 74, 188, 1);
        }
    }

    &.sass {
        background-color: rgba(198, 83, 140, 0.16);
        border-bottom: 1px solid rgba(198, 83, 140, 0.25);

        &:hover {
            background-color: rgba(198, 83, 140, 0.3);
            border-bottom-color: rgba(198, 83, 140, 1);
        }
    }

    &.typescript {
        background-color: rgba(41, 78, 128, 0.16);
        border-bottom: 1px solid rgba(41, 78, 128, 0.25);

        &:hover {
            background-color: rgba(41, 78, 128, 0.3);
            border-bottom-color: rgba(41, 78, 128, 1);
        }
    }
}

.icon-clickable {
    pointer-events: visible !important;
    cursor: pointer;

    &:hover {
        color: #363636 !important;
        opacity: 0.7;
    }
}

.table.is-fullwidth {
    @media all and (max-width: 449px) {
        font-size: 0.8rem;
    }
}

input.MuiInputBase-readOnly {
    background-color: rgba(0, 0, 0, 0.03);
}

.MuiDataGrid-columnHeaderTitleContainerContent span {
    text-align: center;
    line-height: 15px;
    font-weight: bold !important;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.MuiDataGrid-cell--editable input {
    background-color: #fff2b3;
}